const theme = {
  grid: {
    container: "130rem",
    gap: "1.6rem",
  },
  border: {
    radius: {
      xxsmall: "0.1rem",
      xsmall: "0.2rem",
      small: "0.3rem",
      medium: "0.5rem",
      large: "0.8rem",
      xlarge: "1rem",
      xxlarge: "1.6rem",
      full: "500px",
      round: "50%",
    },
  },
  font: {
    family: {
      primary: "Lato",
      secondary: "Inter",
    },
    weight: {
      light: 400,
      normal: 500,
      bold: 700,
    },
    sizes: {
      xxsmall: "1rem",
      xsmall: "1.2rem",
      small: "1.4rem",
      medium: "1.6rem",
      large: "2rem",
      xlarge: "2.4rem",
      xxlarge: "3.2rem",
    },

    lineHeight: {
      xxsmall: "1.2rem",
      xsmall: "1.4rem",
      small: "2rem",
      medium: "2.4rem",
      large: "3.0rem",
      xlarge: "3.6rem",
      xxlarge: "4.0rem",
    },
  },
  heading: {
    family: "Merriweather",
    sizes: {
      h1: "4rem",
      h2: "3.2rem",
      h3: "2.8rem",
      h4: "2.4rem",
      h5: "2rem",
      h6: "1.6rem",
    },
    lineHeight: {
      h1: "5.4rem",
      h2: "4.4rem",
      h3: "4.2rem",
      h4: "3.6rem",
      h5: "2.8rem",
      h6: "2.2rem",
    },
  },
  colors: {
    // primary: "#3B5249",
    // primary50: "#CCDAD5",
    // primary100: "#BBCEC7",
    // primary200: "#A4BEB4",
    // primary300: "#86A89B",
    // primary400: "#628879",
    // primary600: "#34443E",
    // primary800: "#27302C",
    // primary900: "#212826",
    // secondary: "#2F3E46",
    // secondary100: "#B5C4CD",
    // secondary200: "#9CB1BC",
    // secondary300: "#7B97A6",
    // secondary400: "#567281",
    // secondary500: "#2F3E46",
    // secondary600: "#2A3439",
    // secondary700: "#242C30",
    // secondary800: "#1F2528",
    // secondary900: "#1B2022",
    // tertiary: "#52796F",
    // tertiary50: "#D4E2DE",
    // tertiary100: "#C5D8D3",
    // tertiary200: "#B2CBC4",
    // tertiary300: "#98B9B1",
    // tertiary400: "#76A297",
    // tertiary500: "#52796F",
    // tertiary600: "#49645D",
    // tertiary700: "#3F534E",
    // tertiary800: "#374642",
    // tertiary900: "#2F3B38",
    // gray: "#667085",
    // gray25: "#FCFCFD",
    // gray50: "#DDDFDF",
    // gray100: "#D2D5D4",
    // gray200: "#EAECF0",
    // gray400: "#959B9A",
    // gray600: "#626666",
    // gray700: "#535756",
    // gray800: "#474A49",
    // success50: "#BCF8DC",
    // success100: "#A6F6D1",
    // success200: "#88F3C1",
    // success300: "#61EFAD",
    // success400: "#2CEA91",
    // success500: "#12B76A",
    // success600: "#0F9C5A",
    // success700: "#0D844D",
    // success800: "#0B7041",
    // success900: "#096037",
    // warning: "#F79009",
    // warning50: "#FDE5E5",
    // warning100: "#FCDCB1",
    // warning200: "#FCD097",
    // warning300: "#FAC075",
    // warning400: "#F9AC47",
    // warning500: "#F79009",
    // warning600: "#D37A07",
    // warning700: "#B36806",
    // warning800: "#985805",
    // warning900: "#814B04",
    // error: "#F1392B",
    // error50: "#FCD0CD",
    // error100: "#FBC0BC",
    // error200: "#F9ABA6",
    // error400: "#F46A60",
    // error600: "#E21D0F",
    // error700: "#C1190D",
    // error800: "#A4150B",
    // error900: "#8B1209",
    // background50: "#F9FAFB",
    // background100: "#FBFBFC",
    // background200: "#FAFAFA",
    // background300: "#F8F8F9",
    // background400: "#F5F6F7",
    whiteFull: "#FFFFFF",
    blackFull: "#000000",
    offwhite: "#F4F4F4",
    offdark: "#222F3E",
    black: "#212826",
    backgroundWhite: "#F2F3F4",
    backgroundBlue: "#1879A5",
    backgrouGray: "#D0D5DD",
    blue100: "#1879A5",
    blue200: "#004068",
    blue300: "#197AA6",
    gray10: "#D3D3D3",
    secondary50: "#C7D3DA",
    success: "#12B76A",
    gray50: "#DDDFDF",
    gray300: "#D0D5DD",
    gray500: "#667085",
    gray900: "#3C3E3E",
    gray6000: "#2F3E46",
    error300: "#F78F88",
    error500: "#F1392B",
    green500: "#3B5249",
    green700: "#2D3934",
    primary500: "#3B5249",
    gray: "#667085",
    // background600: "#CBCFD2",
    // background700: "#ABB0B4",
    // background800: "#90959B",
    // background900: "#797F85",
    // blue: "#00ACE4",
    // royal: "#004699",
    // midnight: "#2C2657",
    // green: "#27AE60",
    // red: "#C0392B",
    // yellow: "#FFBC3C",
    // white: "#FFFFFF",
    // grey: "#919191",
    // offgrey: "#EBEBEB",
    //
  },
  shadows: {
    xxsmall: "0px 1px 2px #00000029",
    xsmall: "0px 3px 6px #00000029",
  },
  spacings: {
    xxsmall: "0.4rem",
    xsmall: "0.8rem",
    small: "1.2rem",
    medium: "1.6rem",
    large: "2rem",
    xlarge: "2.8rem",
    xxlarge: "3.6rem",
    xxxlarge: "4.4rem",
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
  transition: {
    default: "0.2s ease-in-out",
    fast: "0.1s ease-in-out",
  },
  skeleton: {
    bg: "#E2E5E7",
    gradient: `linear-gradient(
      to right,
      #f5f5f500,
      #f5f5f5aa,
      #f5f5f500
    )`,
  },
  icon: {
    sizes: {
      small: "2rem",
      medium: "2.4rem",
      large: "2.6rem",
      xlarge: "3.2rem",
    },
  },
  input: {
    sizes: {
      small: "3.6rem",
      medium: "4.2rem",
      large: "5rem",
    },
  },
};

export default theme;
