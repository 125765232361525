import React from 'react';

export default function IconHome(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 19.5009V15.0009C14.25 14.802 14.171 14.6113 14.0303 14.4706C13.8897 14.33 13.6989 14.2509 13.5 14.2509H10.5C10.3011 14.2509 10.1103 14.33 9.96967 14.4706C9.82902 14.6113 9.75 14.802 9.75 15.0009V19.5009C9.75 19.6998 9.67098 19.8906 9.53033 20.0313C9.38968 20.1719 9.19891 20.2509 9 20.2509H4.5C4.30109 20.2509 4.11032 20.1719 3.96967 20.0313C3.82902 19.8906 3.75 19.6998 3.75 19.5009V10.8291C3.75168 10.7253 3.77411 10.6229 3.81597 10.5279C3.85783 10.4329 3.91828 10.3472 3.99375 10.2759L11.4937 3.46031C11.632 3.33382 11.8126 3.26367 12 3.26367C12.1874 3.26367 12.368 3.33382 12.5062 3.46031L20.0062 10.2759C20.0817 10.3472 20.1422 10.4329 20.184 10.5279C20.2259 10.6229 20.2483 10.7253 20.25 10.8291V19.5009C20.25 19.6998 20.171 19.8906 20.0303 20.0313C19.8897 20.1719 19.6989 20.2509 19.5 20.2509H15C14.8011 20.2509 14.6103 20.1719 14.4697 20.0313C14.329 19.8906 14.25 19.6998 14.25 19.5009Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
