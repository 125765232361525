import React from 'react';

export default function IconChevronUp(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.5 15L12 7.5L19.5 15" stroke="#004068" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}
