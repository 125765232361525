import React from 'react';

export default function IconCategoryPieChart(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2 13.9997C17.477 13.9997 17.6155 13.9997 17.7278 14.0612C17.8204 14.1119 17.9065 14.2072 17.9478 14.3044C17.9978 14.4222 17.9852 14.5476 17.96 14.7984C17.8296 16.0984 17.3822 17.3511 16.6518 18.4442C15.7727 19.7598 14.5233 20.7852 13.0615 21.3907C11.5997 21.9962 9.99113 22.1546 8.43928 21.846C6.88743 21.5373 5.46197 20.7754 4.34315 19.6565C3.22433 18.5377 2.4624 17.1123 2.15372 15.5604C1.84504 14.0086 2.00347 12.4 2.60897 10.9382C3.21447 9.47641 4.23985 8.22698 5.55544 7.34793C6.64856 6.61752 7.90125 6.17009 9.20131 6.03965C9.45207 6.01449 9.57745 6.00191 9.69528 6.0519C9.79249 6.09314 9.88776 6.17933 9.9385 6.27193C10 6.38419 10 6.52268 10 6.79968V13.1997C10 13.4797 10 13.6197 10.0545 13.7267C10.1024 13.8208 10.1789 13.8972 10.273 13.9452C10.38 13.9997 10.52 13.9997 10.8 13.9997H17.2Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 2.79968C14 2.52268 14 2.38418 14.0615 2.27192C14.1122 2.17932 14.2075 2.09313 14.3047 2.05189C14.4225 2.0019 14.5479 2.01447 14.7987 2.03963C16.6271 2.22302 18.346 3.03198 19.6569 4.34283C20.9677 5.65367 21.7767 7.37258 21.9601 9.20098C21.9852 9.45175 21.9978 9.57714 21.9478 9.69497C21.9066 9.79217 21.8204 9.88744 21.7278 9.93818C21.6155 9.99968 21.477 9.99968 21.2 9.99968L14.8 9.99968C14.52 9.99968 14.38 9.99968 14.273 9.94519C14.1789 9.89725 14.1024 9.82076 14.0545 9.72668C14 9.61972 14 9.47971 14 9.19968V2.79968Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
